/* ============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/

@import "sass-lib";

@media all and (max-width: 1023px) {
	/* global
----------------------------------------------------------------------------------------------*/
	.after_clear:after,
	.wrapper:after,
	section:after {
		clear: both;
		display: block;
		content: " ";
	}
	.before_clear:before {
		clear: both;
		display: block;
		content: " ";
	}

	.wrapper {
		width: 724px;
	}
	.container {
		width: 724px;
	}
	.continue {
		font-size: 13px;
		line-height: 14px;
		span {
			width: 13px;
			height: 13px;
			background-size: 13px;
		}
	}
	.ic_box {
		font-size: 28px;
		line-height: 35px;
		&.top {
			padding-top: 20px;
		}
		&.topCent {
			padding-top: 20px;
		}
	}
	.read {
		font-size: 13px;
	}

	.btn_box {
		span {
			font-size: 11px;
		}
		&.list_arr {
			span {
				line-height: 36px;
			}
			&:after {
				line-height: 36px;
				font-size: 11px;
			}
		}
	}

	/* header
----------------------------------------------------------------------------------------------*/
	header {
		.logo {
			width: 130px;
			margin-top: 8px;
		}
	}

	/* homepage
----------------------------------------------------------------------------------------------*/
	.sliderHome {
		.slider {
			.caption {
				width: 470px;
				text-align: center;
				@include transform(translateY(0%));
				top: 150px;
				left: 0;
				right: 0;
				margin: auto;
				padding-left: 0;
				h1,h2 {
					margin-right: 0;
					font-size: 32px;
					width: auto;
					line-height: 45px;
					margin-bottom: 30px;
				}
				.line {
					display: none !important;
				}
			}
			.images {
				video {
					height: calc(100vw * 1.09375);
				}
			}
			.slick-arrow {
				width: 48px;
				height: 48px;
				background-size: 100%;
			}
		}
		.ckb-group-stay {
			.wrapper {
				&:before {
					width: 100px;
					background-size: 100%;
					top: auto;
					bottom: 0;
					height: 84px;
				}
				h3 {
					width: 100%;
					margin-bottom: 16px;
					text-align: center;
				}
				.link-icon {
					width: 100%;
					justify-content: center;
				}
			}
		}
	}

	.nav_slideHome {
		left: 30px;
		.line {
			display: none;
		}
		.slick-arrow {
			&.slick-next {
				margin-top: 30px;
			}
			&.slick-prev {
				margin-bottom: 30px;
			}
		}
		&:after {
			height: 1px;
			background: $c_ckb;
			content: "";
			display: block;
			width: 80px;
			left: -15px;
			top: 0;
			bottom: 0;
			margin: auto;
			position: absolute;
		}
	}

	.higlight {
		height: 150px;
		.container {
			width: 724px;
		}
		.left {
			padding: 32px 20px 32px 0;
			width: 70%;
		}
		.right {
			width: 30%;
			a {
				line-height: 40px;
				min-width: 192px;
				margin: 0;
				&.booking {
					line-height: 36px;
					margin-left: 0;
					margin-top: 8px;
				}
			}
			// .drop_ship {
			// 	top: auto; bottom: 130px;
			// 	&:before {
			// 		top: auto; bottom: -9px; border-bottom: none; border-top: 10px solid #124936;
			// 	}
			// }
		}
		.slideNews {
			.news {
				a {
					p {
						font-size: 13px;
						line-height: 20px;
						height: 60px;
					}
				}
			}
		}
	}

	.homeAbout {
		.img_about {
			overflow: hidden;
			img {
				min-height: 0;
				width: 66%;
				margin-left: -10%;
			}
		}
		.txt_about {
			.rh_text {
				width: 370px;
				padding-right: 40px;
				h1 {
					font-size: 28px;
					line-height: 36px;
					margin-bottom: 15px;
				}
				p {
					font-size: 13px;
					line-height: 24px;
					max-height: 72px;
					overflow: hidden;
				}
				.lines {
					margin: 20px 0;
					max-width: 85px;
					left: -40px;
				}
				.videos {
					margin-bottom: 25px;
					.wrap_video,
					#vidAbout {
						height: 207px;
					}
					.wrap_video {
						.play_vid {
							width: 65px;
							height: 65px;
							background-size: 20px;
						}
					}
				}
			}
		}
	}
	.top_text {
		width: auto;
		h1 {
			margin-bottom: 15px;
		}
		p {
			font-size: 13px;
			line-height: 20px;
		}
	}

	.businessHome {
		padding: 60px 0 150px;
		.top_text {
			width: auto;
			h1 {
				font-size: 28px;
				line-height: 36px;
			}
		}
		.ListBusiness {
			.item {
				.img {
					height: 185px;
					overflow: hidden;
					img {
						max-width: none;
						width: 255px;
						left: -20%;
						position: relative;
					}
				}
				.capt {
					left: 15px;
					right: 15px;
					h5 {
						font-size: 13px;
						line-height: 16px;
						height: 45px;
					}
					a {
						font-size: 11px;
					}
				}
			}
		}
	}

	.cus_say {
		h3 {
			font-size: 23px;
			margin-bottom: 15px;
			line-height: 30px;
		}
		.wrapper {
			padding: 0 30px 0 60px;
		}
		.slider_customer {
			padding-right: 90px;
			.slide {
				p {
					font-size: 13px;
					line-height: 24px;
				}
				.ket {
					p {
						font-size: 13px;
						line-height: 15px;
						padding-right: 10px;
						margin-right: 10px;
					}
				}
			}
		}
		.nav_slideCus {
			position: relative;
			top: 0;
			@include transform(translateY(0));
			margin: 40px 0 0 50px;
			.slick-arrow {
				width: 35px;
				height: 35px;
				display: inline-block;
				margin: 0 20px !important;
				vertical-align: top;
				@include transform(rotate(-90deg));
				&.slick-prev {
					svg {
						width: 35px !important;
						height: 35px !important;
					}
				}
			}
			&:before {
				left: 80px;
				right: auto;
				width: 2px;
				height: 50px;
			}
		}
	}

	.homeNews {
		padding: 50px 0 60px;
		.top_text {
			h1 {
				font-size: 28px;
			}
		}
		.listnews {
			.item {
				width: 31.75%;
				margin-right: 2.375%;
				&:nth-of-type(4) {
					display: none;
				}
				&:nth-of-type(3) {
					margin-right: 0;
				}
				a {
					h6 {
						line-height: 22px;
						min-height: 68px;
						max-height: 68px;
					}
					p {
						height: 80px;
						overflow: hidden;
					}
				}
			}
		}
	}

	.homeCareer {
		.txt_career {
			width: 290px;
			padding: 45px 0;
			.lines {
				max-width: 70px;
				left: -15px;
				margin: 15px 0;
			}
		}
		.img_career {
			width: 65%;
		}
	}

	/* middle
----------------------------------------------------------------------------------------------*/

	.middle {
		padding: 90px 0 60px;
		.wrapper {
			width: 724px;
		}
	}
	.banner {
		.title_pg {
			h1,
			h2 {
				font-size: 33px;
				line-height: 40px;
			}
		}
	}
	.nav_sub {
		width: 230px;
		margin-top: -40px;
		.sub_act {
			height: 40px;
			padding: 0 42px 0 15px;
			span {
				font-size: 11px;
				left: 15px;
				display: block;
				width: 165px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			i {
				width: 40px;
				height: 40px;
				&:after {
					width: 16px;
					background-size: 16px;
					height: 10px;
				}
			}
		}
		.drop_sub {
			ul {
				li {
					a {
						font-size: 11px;
						padding: 15px 18px;
						width: 230px;
					}
				}
			}
		}
	}

	.wrap_text {
		width: 640px;
		p {
			font-size: 13px;
			line-height: 20px;
			margin-bottom: 20px;
		}
		> h3 {
			font-size: 16px;
		}
		> ul {
			li {
				font-size: 13px;
				line-height: 22px;
				padding-left: 25px;
				margin-bottom: 10px;
				&:before {
					left: 0;
					top: 8px;
				}
			}
		}
		> ol {
			padding-left: 25px;
			li {
				padding-left: 0;
				font-size: 13px;
				line-height: 22px;
				margin-bottom: 10px;
			}
		}
	}
	.over_wrap {
		margin: 0 -42px 30px;
	}
	.title_content {
		padding-top: 50px;
		h1 {
			margin-bottom: 30px;
			padding-bottom: 20px;
		}
	}
	.inner_content {
		p {
			font-size: 13px;
			line-height: 22px;
			margin-bottom: 20px;
		}
	}
	.listing {
		.item {
			.detail {
				font-size: 13px;
			}
		}
		&.listIndustry {
			.item {
				margin-bottom: 30px;
				.images {
					.txt_img {
						img {
							width: 80px;
						}
						h6 {
							font-size: 16px;
						}
					}
				}
				.txt_hover {
					left: 18px;
					right: 18px;
					font-size: 13px;
					line-height: 20px;
					p {
						font-size: 13px;
						line-height: 20px;
					}
				}
			}
		}
		&.listService {
			.item {
				.img {
					width: 150px;
					img {
						max-width: none;
						width: 200px;
						position: relative;
						left: -15%;
					}
				}
				.text.v_center {
					position: relative;
					top: 0;
					@include transform(translateY(0));
					width: 185px;
					.ic_box {
						font-size: 13px;
						line-height: 20px;
						margin-bottom: 8px;
					}
					p {
						font-size: 11px;
						line-height: 18px;
						max-height: 72px;
					}
				}
			}
		}
		&.listIntegrated {
			margin: 40px 0;
			.item {
				padding: 0 10px;
				h6 {
					font-size: 11px;
					line-height: 16px;
				}
			}
		}
		&.subIntegrate {
			.item {
				margin-bottom: 30px;
				.img {
					width: 300px;
				}
				.text.v_center {
					width: 310px;
					padding-right: 15px;
					@include boxSizing(border-box);
					h3 {
						font-size: 19px;
					}
					p {
						max-height: 86px;
						overflow: hidden;
					}
				}
			}
		}
		&.col3 {
			.item {
				margin-bottom: 30px;
			}
		}
		&.listWho,
		&.listCr {
			.dec {
				padding-top: 15px;
				h6 {
					font-size: 16px;
					line-height: 22px;
					margin-bottom: 10px;
					height: 44px;
				}
				p {
					font-size: 11px;
					line-height: 18px;
					max-height: 72px;
					overflow: hidden;
					height: 72px;
				}
			}
		}
	}

	.sliderContent {
		.slick-arrow {
			width: 65px;
			height: 45px;
			&:after {
				width: 10px;
				height: 17px;
			}
			&.slick-prev {
				right: 65px;
			}
			&.slick-next {
				&:before {
					height: 25px;
				}
			}
		}
	}

	.about_sec {
		padding: 50px 0;
		.text_about {
			.ic_box {
				font-size: 23px;
				margin-bottom: 20px;
			}
			p {
				font-size: 13px;
				line-height: 22px;
			}
			a.continue {
				margin-top: 10px;
			}
		}
		.img_compli {
			width: 50%;
		}
		.img_respons {
			width: 386px;
			height: 366px;
			img {
				width: 300px;
				position: absolute;
				bottom: 0;
			}
		}
		&.who {
			.text_about {
				width: 370px;
				padding-top: 0;
				.ic_box {
					padding-bottom: 20px;
					i {
						width: 70px;
					}
				}
				p {
					max-height: 132px;
					overflow: hidden;
				}
			}
		}
		&.group {
			.wrap_text {
				p {
					font-size: 13px;
					line-height: 22px;
				}
			}
		}
		&.compliance {
			min-height: 0;
			.text_about {
				width: 360px;
			}
		}
		&.respons {
			min-height: 350px;
			.text_about {
				width: 400px;
				p {
					max-height: 132px;
					overflow: hidden;
				}
			}
		}
		&:last-of-type {
			margin-bottom: -60px;
		}
	}

	.sliderGroup {
		margin: 40px 60px 0;
		.slide {
			width: 187px;
			.dec {
				padding: 15px 0 0;
				h6 {
					margin-bottom: 6px;
					font-size: 16px;
				}
				p {
					font-size: 11px;
					line-height: 18px;
					max-height: 72px;
					overflow: hidden;
				}
			}
		}
		.slick-arrow {
			width: 40px;
			height: 40px;
			&.slick-prev {
				left: -60px;
			}
			&.slick-next {
				right: -60px;
			}
		}
	}

	.listVisi {
		h3 {
			font-size: 19px;
			margin-bottom: 15px;
			line-height: 26px;
		}
		.list {
			.ico {
				width: 45px;
			}
		}
		ul {
			li {
				font-size: 13px;
				line-height: 22px;
			}
		}
	}
	.slideYear {
		.year {
			&.slick-center {
				h3 {
					font-size: 57px;
				}
			}
		}
	}
	.slideText {
		width: 445px;
		.text_history {
			ul {
				li {
					font-size: 13px;
					line-height: 22px;
				}
			}
		}
	}

	.listAward {
		width: auto;
		.list {
			width: 80%;
			figure {
				width: 220px;
				img {
					width: 200px;
				}
			}
			.ket_award {
				margin-left: 250px;
				h5 {
					font-size: 16px;
					margin-bottom: 15px;
					&:after {
						width: 70px;
					}
				}
				p {
					font-size: 13px;
					line-height: 22px;
				}
				.by {
					font-size: 13px;
				}
			}
		}
	}
	.listFact {
		.item {
			width: 31.5%;
			margin-right: 2%;
			&:nth-child(4n) {
				margin-right: 2%;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
		}
	}

	#map_logistic {
		height: 380px;
	}
	.map_info {
		h1 {
			font-size: 16px;
		}
	}

	.subCompliance {
		.box {
			padding-right: 5.4%;
			.ico {
				width: 120px;
				margin-bottom: 25px;
			}
			h5 {
				font-size: 19px;
			}
			p {
				font-size: 13px;
				line-height: 22px;
			}
			&:nth-child(2) {
				padding-left: 5.4%;
			}
		}
	}

	.content_Compliance {
		.tab_nav {
			.tab {
				width: 220px;
				h5 {
					font-size: 19px;
				}
				.ico {
					width: 120px;
					margin: 0 auto 15px;
					img {
					}
				}
				&:nth-child(2) {
					.ico {
						width: 130px;
					}
				}
			}
		}
		.box_white {
			padding: 50px;
			border-width: 2px;
			.content_tab {
				> h3 {
					font-size: 19px;
				}
				ul {
					li {
						font-size: 13px;
						line-height: 22px;
					}
				}
			}
			&:after {
				width: 180px;
				background-size: 180px;
				bottom: -25px;
			}
		}
		.slideSertifikat {
			.slide {
				padding: 10px;
				figure {
					width: 220px;
					img {
						width: 200px;
					}
				}
				.ket_award {
					margin-left: 250px;
					h5 {
						font-size: 16px;
						margin-bottom: 15px;
						&:after {
							width: 70px;
						}
					}
					p {
						font-size: 13px;
					}
					.by {
						font-size: 13px;
					}
				}
			}
		}
	}
	.sec_news {
		padding: 45px 22px;
		.img_news {
			left: 22px;
			top: 30px;
			img {
				max-width: 805px;
			}
		}
		.text_news {
			width: 52%;
			p {
				font-size: 13px;
			}
			.newslisting {
				.list {
					padding: 0 20px 20px;
					h6 {
						font-size: 13px;
						line-height: 22px;
					}
					p {
						font-size: 11px;
						line-height: 18px;
					}
				}
			}
		}
		&.indust {
			.img_news {
				img {
					right: -34%;
				}
			}
		}
	}

	.txt_filter {
		margin-bottom: 35px;
		.left {
			width: 65%;
			p {
				font-size: 13px;
				line-height: 19px;
			}
		}
		.right {
			width: 220px;
			.not_src {
				+ .select2 {
					.select2-selection {
						height: 40px;
					}
					.select2-selection__rendered {
						font-size: 11px;
						line-height: 19px;
					}
					.select2-selection__arrow {
						width: 40px;
						height: 39px;
					}
				}
			}
		}
	}
	.select2-results__option {
		font-size: 11px;
		padding: 10px 20px;
	}

	.pg_news {
		padding-top: 50px;
		> .ic_box {
			margin-bottom: 20px;
		}
		&.detail {
			> .images {
				margin-bottom: 30px;
			}
		}
	}
	.gridNews {
		.box {
			padding: 0 40px 30px;
			h3 {
				font-size: 19px;
				line-height: 26px;
			}
			.img {
				margin-bottom: 20px;
			}
			.shortdec {
				padding-left: 85px;
				&:after {
					left: 50px;
				}
				&:before {
					width: 50px;
				}
				.categ {
					label {
						font-size: 13px;
					}
					nav {
						margin-left: 85px;
						a {
							font-size: 13px;
						}
					}
				}
				.dt {
					font-size: 13px;
					margin-bottom: 10px;
				}
			}
		}
	}

	.pg_career {
		.img_carrer {
			width: 500px;
			margin: 0 0 -40px;
		}
		.text_career {
			width: 280px;
			h3 {
				font-size: 23px;
				line-height: 30px;
			}
		}
	}

	.sec_bottom {
		padding-bottom: 65px;
		margin-bottom: -65px;
		.ic_box {
			font-size: 23px;
			line-height: 32px;
		}
		.slideRecommend {
			margin: 0 60px;
			.slick-list {
				padding: 0 10px;
			}
			.item {
				width: 182px;
				.text {
					padding: 15px 0 10px;
					h6 {
						font-size: 13px;
						line-height: 18px;
						margin-bottom: 5px;
					}
					p {
						font-size: 11px;
						line-height: 16px;
						max-height: 64px;
					}
				}
				&:hover {
					.img {
						@include boxShadow(0 0 20px -5px rgba(0, 0, 0, 0.6));
					}
				}
			}
			.slick-arrow {
				width: 40px;
				height: 40px;
				top: 65px;
				&.slick-prev {
					left: -50px;
				}
				&.slick-next {
					right: -50px;
				}
			}
		}
	}
	.section_bottom {
		.topTop {
			width: 55px;
			height: 60px;
			.icon {
				margin-bottom: 8px;
			}
			h6 {
				font-size: 10px;
				font-weight: 500;
			}
		}
		.pagging {
			a {
				width: 25px;
				height: 30px;
				line-height: 30px;
			}
		}
		.btn_bk {
			line-height: 40px;
			font-size: 13px;
		}
	}

	#id_contactMap {
		height: 600px;
	}
	.map_ket {
		width: 360px;
		padding: 35px;
		h6 {
			font-size: 13px;
		}
		p {
			font-size: 13px;
			line-height: 19px;
		}
		.bx_btn {
			a {
				line-height: 50px;
				font-size: 16px;
				width: 83px;
			}
		}
	}
	.btm_contact .img {
		display: none;
	}
	.nav_sub {
		&.office {
			ul {
				li {
					a {
						font-size: 11px;
						line-height: 13px;
						padding-bottom: 8px;
						@include boxSizing(border-box);
						height: 47px;
					}
				}
			}
		}
	}
	.listnetwork {
		.item {
			width: 48%;
			margin-right: 4%;
			margin-bottom: 35px;
			&:nth-of-type(3n) {
				margin-right: 4%;
			}
			&:nth-of-type(2n) {
				margin-right: 0;
			}
			.keterangan {
				.loc {
					span {
						font-size: 13px;
						padding-top: 3px;
						&:before {
							height: 28px;
						}
					}
				}
			}
		}
	}
	.map_content {
		.dec_map {
			h4 {
				font-size: 16px;
			}
		}
	}
	.inquiry {
		.txt_filter {
			.right .not_src + .select2 .select2-selection {
				height: 40px;
			}
			.right .not_src + .select2 .select2-selection__arrow {
				height: 40px;
			}
		}
	}
	.box_form {
		.row {
			margin-bottom: 50px;
			> h3 {
				font-size: 20px;
			}
			> p {
				font-size: 14px;
				line-height: 20px;
			}
			.col {
				input[type="text"],
				input[type="email"],
				input[type="number"],
				select,
				textarea,
				input[type="password"] {
					font-size: 16px;
				}
				select {
					background-size: 16px;
				}
				.field {
					.lbel {
						font-size: 15px;
					}
					&.focus {
						.lbel {
							font-size: 14px;
						}
					}
					&.ck_short {
						> label {
							font-size: 14px;
						}
					}
				}
				.input_file {
					height: 40px !important;
					span {
						font-size: 15px;
					}
				}
				.ck_radio,
				.ck_checkbox {
					font-size: 16px;
				}
				.btn_box.list_arr {
					span {
						font-size: 13px;
						line-height: 46px;
					}
					&:after {
						line-height: 46px;
						font-size: 13px;
					}
				}
			}
			> label {
				font-size: 15px;
			}
		}
	}

	.btm_map {
		margin-bottom: -60px;
	}

	.slideCSR {
		.slick-arrow {
			width: 60px;
			height: 35px;
			&.slick-prev {
				right: 60px;
			}
		}
		.sliderCapt {
			.capt {
				padding-top: 60px;
				h2 {
					font-size: 19px;
					line-height: 26px;
				}
			}
		}
	}
	.accordion {
		.title_acc {
			padding: 20px;
		}
	}

	.slideTestimoni {
		width: 724px;
		margin-top: 50px;
		.box_img {
			width: 190px;
		}
		.box_testimoni {
			width: 534px;
			.text {
				h2 {
					font-size: 23px;
					padding: 15px 120px 15px 20px;
				}
			}
			.isi {
				padding: 65px 60px 60px;
				p {
					font-size: 13px;
					line-height: 26px;
				}
				&:before {
					width: 50px;
					background-size: 50px;
					top: 35px;
					left: 20px;
				}
				.ket {
					margin-top: 25px;
					span {
						font-size: 13px;
					}
				}
			}
			.slick-arrow {
				height: 54px;
				width: 60px;
				&.slick-prev {
					right: 60px;
				}
			}
		}
	}

	.slideMangement {
		.slide {
			figure {
				width: 250px;
			}
			.txt {
				width: 350px;
				margin-left: 280px;
				h2 {
					font-size: 19px;
					padding-bottom: 15px;
					margin-bottom: 15px;
					&:after {
						width: 92px;
						left: -50px;
					}
				}
			}
		}
	}
	.nav_slideMagement {
		&:before {
			width: 80px;
		}
	}

	.list_result {
		.list {
			h3 {
				font-size: 18px;
				line-height: 22px;
			}
			p {
				font-size: 14px;
			}
			a.continue {
				font-size: 13px;
			}
		}
	}
	.btm_contact {
		margin-bottom: -60px;
	}

	.listManagent {
		.item {
			.txt {
				h2 {
					font-size: 28px;
					line-height: 34px;
					margin-bottom: 10px;
				}
				h6 {
					font-size: 14px;
				}
			}
		}
	}

  .box-wa {
    a {
      font-size: 13px;
      i {
        width: 24px !important;
        height: 24px !important;
        background-size: 100%;
      }
    }
  } 

  .full-section {
    figcaption {
      margin-top: -60px; 
    }
  }


	/* footer
----------------------------------------------------------------------------------------------*/
	footer {
		.top_footer {
			.right {
				display: none;
			}
			.foot_addrs {
				.txt {
					position: relative;
					display: block;
					max-width: 240px;
					.sos {
						position: absolute;
						top: 50%;
						@include transform(translateY(-50%));
						margin: auto;
						left: 140%;
						a {
							display: block;
							min-width: 180px;
						}
					}
				}
			}
		}
		.btm_footer {
			overflow: hidden;
			height: auto;
			padding: 32px 0;
			.wrapper {
				margin: 0 30px;
				display: -webkit-box;
				display: -moz-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				flex-flow: column;
				-webkit-flex-flow: column;
				-moz-flex-flow: column;
				-ms-flex-flow: column;
				-o-flex-flow: column;
			}
			.left {
				width: 100%;
				margin-top: 0px;
				float: none;
				display: block;
				text-align: center;
				p {
					font-size: 14px;
					line-height: 18px;
					&.desc {
						font-size: 13px;
					}
				}
			}
			.right {
				width: 100%;
				position: relative;
				top: unset;
				@include transform(translate(0, 0));
				display: block;
				text-align: center;
				top: 0;
				margin: 0;
				.list_foot {
					@include transform(scale(0.8));
					a {
						display: inline-block;
					}
				}
			}
		}
	}
	.homeAbout {
		.txt_about {
			flex-wrap: wrap;
			flex-direction: column-reverse;
			.rh_text {
				width: 100%;
				padding-left: 0;
				h1,h2 {
					margin-bottom: 20px;
				}
			}
		}
		.videos {
			width: 100%;
			margin-top: 32px;
		}
	}
	.businessHome {
		padding: 30px 0;
		.wrapper {
			padding: 60px 40px;
		}
		.top_text {
			h6 {
				font-size: 16px;
			}
		}
		.list-services {
			.item-services {
				padding: 16px 0;
			}
		}
	}
	.home-global-player {
		padding: 32px 0 24px;
		.img-global {
			width: 480px;
			margin: 0 auto;
			&:before {
				width: 100%;
			}
		}
		.desc-global {
			width: 100%;
			padding-right: 0;
			margin-bottom: 40px;
		}
	}
	.cus_say {
		.wrapper {
			padding: 0;
			width: 724px;
		}
		.slider_customer {
			.slick-arrow {
				right: 20px;
			}
		}
	}

} /* end media */

@media all and (min-width: 767px) {
	.nav_sub.office {
		ul {
			display: block !important;
		}
	}
	.nav_sub.office .arr_drop {
		display: none;
	}
}
